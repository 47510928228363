import { SpeedDialAction } from '@mui/material'
import { uid } from 'react-uid'
import { useQueryContacts } from '../../../../api/patient/hooks'
import { HelpDial } from '../../../../components'
import { visitDetailContactsActions } from '../../../../components/HelpDial/HelpDialActions'
import { HelpSpeedDialActions } from '../../../../components/HelpDial/types'
import { ContactGroup } from './ContactGroup'

export const Contacts = ({
    patientId,
    patientName,
    insuranceNumber,
}: {
    patientId?: number
    insuranceNumber?: string
    patientName?: string
}) => {
    const { data: contactData, isLoading } = useQueryContacts(patientId)

    return (
        <>
            <ContactGroup
                isLoading={isLoading}
                title='Kontaktní osoby'
                insuranceNumber={insuranceNumber}
                patientName={patientName}
                contacts={contactData?.data || Array.from(new Array(2))}
            />
            <HelpDial>
                {({ handleActionClick }) => {
                    return visitDetailContactsActions.map(
                        ({ name, icon, modalName }: HelpSpeedDialActions) => (
                            <SpeedDialAction
                                key={uid(modalName)}
                                icon={icon}
                                tooltipTitle={name}
                                tooltipPlacement='right'
                                tooltipOpen
                                sx={{
                                    '& .MuiSpeedDialAction-staticTooltipLabel':
                                        {
                                            width: 'max-content',
                                        },
                                }}
                                onClick={handleActionClick(modalName)}
                            />
                        ),
                    )
                }}
            </HelpDial>
        </>
    )
}
