import {
    Avatar,
    Box,
    IconButton,
    Skeleton,
    Stack,
    Typography,
} from '@mui/material'
import { MouseEvent, useContext } from 'react'
import { formatPhone } from '../../../../utils'
import type { ContactCardProps } from '../../types'
import classnames from 'classnames'
import { Close, PhoneEnabledRounded, SmsRounded } from '@mui/icons-material'
import { useSnackbar } from 'notistack'
import { AuthContext } from '../../../../providers'

export const ContactCard = ({
    name,
    familyRelationship,
    phone,
    patientName,
    insuranceNumber,
    isLoading,
}: ContactCardProps) => {
    const { enqueueSnackbar, closeSnackbar } = useSnackbar()

    const { user } = useContext(AuthContext)

    const anonymizeInsuranceNumber = (insuranceNumber?: string) => {
        if (!insuranceNumber) return ''
        const visibleLength = insuranceNumber.length === 8 ? 5 : 6
        return insuranceNumber.slice(0, visibleLength) + '...'
    }

    const handleCallContact = (e: MouseEvent) => {
        e.preventDefault()
        if (!phone) {
            return enqueueSnackbar(`Telefon neuveden - není možné zavolat.`, {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
                action: (key) => (
                    <IconButton onClick={() => closeSnackbar(key)}>
                        <Close color='secondary' />
                    </IconButton>
                ),
            })
        } else {
            window.location.href = `tel:${phone}`
        }
    }

    const handleSendSmSContact = (e: MouseEvent) => {
        e.preventDefault()
        if (!phone) {
            return enqueueSnackbar(
                `Telefon neuveden - není možné poslat SMS.`,
                {
                    variant: 'error',
                    anchorOrigin: { vertical: 'top', horizontal: 'center' },
                    action: (key) => (
                        <IconButton onClick={() => closeSnackbar(key)}>
                            <Close color='secondary' />
                        </IconButton>
                    ),
                },
            )
        } else if (isFixedLine(phone)) {
            return enqueueSnackbar(`Nelze poslat SMS na pevnou linku.`, {
                variant: 'error',
                anchorOrigin: { vertical: 'top', horizontal: 'center' },
                action: (key) => (
                    <IconButton onClick={() => closeSnackbar(key)}>
                        <Close color='secondary' />
                    </IconButton>
                ),
            })
        } else {
            let messageText = ''

            const familyRelationShipLowerCase =
                familyRelationship.toLocaleLowerCase()

            switch (true) {
                case familyRelationShipLowerCase.includes('pacient'):
                    messageText = `Dobrý den, návštěva se uskuteční za XY minut. S pozdravem ${user?.fullName} (${user?.jobTitle})`
                    break
                case familyRelationShipLowerCase.includes('lékař'):
                    messageText = `Dobrý den, prosím o konzultaci k pacientovi ${patientName} (r.č. ${anonymizeInsuranceNumber(
                        insuranceNumber,
                    )}). Děkuji ${user?.fullName} (${user?.jobTitle})`
                    break
                default:
                    messageText = `Dobrý den, zdravotní návštěva pacienta ${patientName} proběhla v pořádku. S pozdravem ${user?.fullName} (${user?.jobTitle})`
                    break
            }

            const message = encodeURIComponent(messageText)

            window.location.href = `sms:${phone}?&body=${message}`
        }
    }

    const isFixedLine = (number: string) => {
        if (!number) return true
        return (
            number[0] === '2' ||
            number[0] === '3' ||
            number[0] === '4' ||
            number[0] === '5'
        )
    }

    return (
        <Box
            className={classnames(
                'relative h-full rounded border border-solid border-orange-400 bg-orange-100 p-3 pb-12 text-center text-gray-700',
                {
                    'border-gray-400 bg-gray-200': !phone,
                },
            )}
        >
            {isLoading ? (
                <Skeleton
                    animation='wave'
                    variant='circular'
                    width={40}
                    height={40}
                    className='mx-auto bg-orange-300'
                />
            ) : (
                <Avatar
                    className={classnames(
                        'mx-auto mb-3 bg-orange-300 text-white-reduced',
                        {
                            'bg-gray-400': !phone,
                        },
                    )}
                />
            )}
            <Box className='pb-2'>
                {isLoading ? (
                    <Skeleton
                        animation='wave'
                        height={14}
                        width='60%'
                        sx={{
                            mx: 'auto',
                        }}
                    />
                ) : name ? (
                    <Typography className='text-sm font-medium'>
                        {name}
                    </Typography>
                ) : null}
                {isLoading ? (
                    <Skeleton
                        animation='wave'
                        height={14}
                        width='30%'
                        sx={{
                            mx: 'auto',
                        }}
                    />
                ) : (
                    <Typography className='text-xs font-medium text-gray-600'>
                        {familyRelationship}
                    </Typography>
                )}
            </Box>
            {isLoading ? (
                <Skeleton
                    animation='wave'
                    height={14}
                    width='60%'
                    sx={{
                        mx: 'auto',
                    }}
                />
            ) : phone ? (
                <Typography className='text-sm font-medium'>
                    {formatPhone(phone)}
                </Typography>
            ) : (
                <Typography className='text-sm font-medium'>
                    neuvedeno
                </Typography>
            )}
            <Stack
                className='absolute -bottom-2 -right-2 gap-2'
                direction='row'
            >
                {isLoading ? (
                    <Skeleton
                        variant='rectangular'
                        width={68}
                        height={68}
                        className='rounded'
                        animation='wave'
                    />
                ) : (
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation()
                            handleCallContact(e)
                        }}
                        className={classnames(
                            'h-[3.0625rem] w-[3.0625rem] rounded bg-orange-300 py-4 text-white-reduced transition-colors hover:bg-orange-400',
                            {
                                'bg-gray-500 opacity-50 focus:bg-gray-500':
                                    !phone,
                            },
                        )}
                    >
                        <PhoneEnabledRounded className='text-3xl' />
                    </IconButton>
                )}
                {isLoading ? (
                    <Skeleton
                        variant='rectangular'
                        width={68}
                        height={68}
                        className='rounded'
                        animation='wave'
                    />
                ) : (
                    <IconButton
                        onClick={(e) => {
                            e.stopPropagation()
                            handleSendSmSContact(e)
                        }}
                        className={classnames(
                            'h-[3.0625rem] w-[3.0625rem] rounded bg-orange-300 py-4 text-white-reduced transition-colors hover:bg-orange-400',
                            {
                                'bg-gray-500 opacity-50 focus:bg-gray-500':
                                    !phone || isFixedLine(phone),
                            },
                        )}
                    >
                        <SmsRounded className='text-3xl' />
                    </IconButton>
                )}
            </Stack>
        </Box>
    )
}
