import {
    PatientFilterQueries,
    PatientsCardFilterType,
    PatientsFilterItems,
} from '../types'

export const getPatientsCardFilters = (
    dynamicFilters: PatientsFilterItems[],
    patientsQueryFilters?: PatientFilterQueries,
): PatientsFilterItems[] => {
    const staticFilters: PatientsFilterItems[] = [
        {
            title: 'Archiv',
            isSelect: false,
            type: 'archived',
            items: [
                {
                    active: true,
                    value: false,
                    name: 'Aktuální',
                },
                {
                    active: false,
                    value: true,
                    name: 'Archivní',
                },
            ],
        },
        {
            title: 'Odbornost',
            isSelect: false,
            type: 'expertiseTypes',
            items: [
                { active: false, value: '720', name: '720' },
                { active: false, value: '925', name: '925' },
                { active: false, value: '926', name: '926' },
            ],
        },
        {
            title: 'Stav pacienta',
            isSelect: false,
            type: 'patientStates',
            items: [
                { active: false, value: 'Active', name: 'Aktivní' },
                { active: false, value: 'Waiting', name: 'Čekatel' },
                {
                    active: false,
                    value: 'Hospitalized',
                    name: 'Hospitalizován',
                },
                { active: false, value: 'Ended', name: 'Péče ukončena' },
                { active: false, value: 'Dead', name: 'Zemřel' },
            ],
        },
    ]

    if (dynamicFilters) {
        staticFilters.push(...dynamicFilters)
    }

    // If no filters are provided, return the static structure
    if (!patientsQueryFilters) return staticFilters

    // Map filters from query params to static filter structure
    Object.keys(patientsQueryFilters).forEach((filterKey) => {
        const queryValues = patientsQueryFilters[filterKey]
        const filterGroup = staticFilters.find(
            (filter) => filter.type === filterKey,
        )

        if (filterGroup && queryValues) {
            // Normalize queryValues to always be an array
            const normalizedQueryValues = Array.isArray(queryValues)
                ? queryValues.map((val) =>
                      typeof filterGroup.items[0]?.value === 'boolean'
                          ? val === 'true'
                          : val,
                  )
                : [
                      typeof filterGroup.items[0]?.value === 'boolean'
                          ? queryValues === 'true'
                          : queryValues,
                  ]

            // Adjust the 'active' state for matching filter items
            filterGroup.items = filterGroup.items.map((item) => ({
                ...item,
                active: normalizedQueryValues.includes(item.value),
            }))
        }
    })

    return staticFilters
}

export const singleSelectPatientsGroup: PatientsCardFilterType[] = ['archived']
