import { Box, Grid, Stack, Typography } from '@mui/material'
import { useUIDSeed } from 'react-uid'
import type { ContactGroupProps } from '../../types'
import { ContactCard } from './ContactCard'

export const ContactGroup = ({
    title,
    contacts,
    patientName,
    insuranceNumber,
    isLoading,
    ...elementProps
}: ContactGroupProps) => {
    const uid = useUIDSeed()

    return (
        <Box {...elementProps}>
            <Typography className='mb-4 text-sm font-medium'>
                {title}
            </Typography>
            <Grid
                container
                spacing={2}
            >
                {contacts.map((contact, id) => (
                    <Grid
                        key={uid(`${contact}-${id}`)}
                        item
                        xs={12}
                        sm={6}
                        md={4}
                    >
                        <ContactCard
                            insuranceNumber={insuranceNumber}
                            patientName={patientName}
                            isLoading={isLoading}
                            {...contact}
                        />
                    </Grid>
                ))}
                {!contacts.length && !isLoading && (
                    <Grid
                        item
                        xs={12}
                    >
                        <Stack
                            justifyContent='space-between'
                            direction='row'
                        >
                            <Typography className='pt-1 text-sm'>
                                Pacient nemá žádné dostupné kontakty.
                            </Typography>
                            <Box className='relative w-[21px]'>
                                <Box className='h-[2px] w-[100%] rounded bg-gray-300' />
                                <Box className='absolute right-0 h-[16px] w-[2px] rounded bg-gray-300' />
                            </Box>
                        </Stack>
                    </Grid>
                )}
            </Grid>
        </Box>
    )
}
